































import Vue from "vue"
import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"
import OpacityTransition from "@/components/GroupTeams/Common/GameModes/OpacityTransition.vue"
import SlideLeftTransition from "@/components/GroupTeams/Common/GameModes/SlideLeftTransition.vue"
import SlideRightTransition from "@/components/GroupTeams/Common/GameModes/SlideRightTransition.vue"
import useStore from "@/use/useStore"
import { computed } from "@vue/composition-api"
export default Vue.extend({
  name: "PlayLayout",
  components: {
    MissionCard,
    OpacityTransition,
    SlideLeftTransition,
    SlideRightTransition,
    PillCountdown: () =>
      import("@/components/GroupTeams/Common/PillCountdown.vue")
  },

  props: {
    expand: Boolean,
    timer: {
      type: Boolean,
      default: true
    }
  },

  setup() {
    const { store } = useStore()
    const endTime = computed(() => {
      const int = parseInt(store.getters.gameStatus?.endTime) || 0
      const endTime = int - Date.now()
      return endTime > 0 ? endTime : 0
    })

    const autopilot = computed(() => {
      return store.getters.game?.autopilot
    })

    return {
      endTime,
      autopilot
    }
  },
  computed: {
    slotDefault() {
      return this.$slots.default
    }
  }
})
