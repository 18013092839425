var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "play-layout", attrs: { row: "", "fill-height": "" } },
    [
      _c(
        "v-flex",
        {
          staticClass: "play-layout__left",
          attrs: { "d-flex": "", "align-center": "", xs3: "", "ml-2": "" },
        },
        [
          _c(
            "SlideRightTransition",
            [
              _vm.$slots.left
                ? _c("MissionCard", [_vm._t("left")], 2)
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        {
          staticClass: "play-layout__center",
          attrs: { xs6: "", "pr-2": "", "pl-2": "" },
        },
        [
          _c(
            "OpacityTransition",
            [
              _c(
                "MissionCard",
                [
                  _vm.timer
                    ? _c("PillCountdown", {
                        staticClass: "play-layout__timer",
                        attrs: { endTime: _vm.endTime },
                      })
                    : _vm._e(),
                  _vm._t("default"),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        {
          staticClass: "play-layout__right",
          attrs: { "d-flex": "", "align-center": "", xs3: "", "mr-2": "" },
        },
        [_c("SlideLeftTransition", [_vm._t("right")], 2)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }